/* >> Color Platte */
/* << Color Platte */
.pull-left {
  float: left; }

.pull-right {
  float: right; }

.no-p {
  padding: 0 !important; }

.p-l {
  padding-left: 20px; }

.p-r {
  padding-right: 20px; }

.p-t {
  padding-top: 20px; }

.p-b {
  padding-bottom: 20px; }

.p-l-lg {
  padding-left: 40px !important; }

.p-b-lg {
  padding-bottom: 40px !important; }

.p-r-lg {
  padding-right: 40px !important; }

.m-b {
  margin-bottom: 20px; }

.m-b-sm {
  margin-bottom: 10px; }

.m-t {
  margin-top: 20px; }

.m-b-hg {
  margin-bottom: 30px; }

.m-l {
  margin-left: 20px; }

.m-l-md {
  margin-left: 15px; }

.m-r {
  margin-right: 20px; }

.p-all {
  padding: 20px; }

.p-all-lg {
  padding: 30px; }

.big-number {
  font-size: 30px; }

.huge-number {
  font-size: 40px; }

.text-md {
  font-size: 17px; }

.text-sm, .text-small {
  font-size: 14px; }

.text-mini {
  font-size: 12px; }

.text-bg {
  font-size: 19px; }

.text-large {
  font-size: 24px; }

.text-huge {
  font-size: 32px; }

.color-yellow, .color-warn {
  color: #eebb33 !important; }

.color-red {
  color: #f9727a; }

.color-blue {
  color: #6178ea; }

.color-purple {
  color: #7874d8; }

.color-title-purple {
  color: #a38dfc; }

.title-purple {
  background: #a38dfc !important; }

.color-blue-title {
  color: #6178ea; }

.color-mute, .color-muted {
  color: #c7c7c7; }

.color-black {
  color: #333; }

.color-text {
  color: #5a5683; }

.color-grey {
  color: #666; }

.back-blue {
  background: #00bfe1; }

.primary-blue {
  background: #36508b; }

.primary-blue-color {
  background: #36508b; }

.back-green {
  background: #7cd234; }

.blur-green {
  color: #666 !important; }
  .blur-green:hover {
    color: #7cd234 !important; }

.blur-primary-blue {
  color: #666 !important; }
  .blur-primary-blue:hover {
    color: #36508b !important; }

.color-green {
  color: #7cd234 !important; }

.color-primary-blue {
  color: #36508b !important; }

.back-red {
  background: #FD7076; }

.back-yellow {
  background: #eebb33 !important; }

.back-yellow-btn {
  background: #eebb33 !important;
  border: 1px solid #eebb33;
  color: #fff; }
  .back-yellow-btn:hover {
    box-shadow: 0 0 3px rgba(1, 1, 1, 0.5);
    background: #eebb33 !important;
    border: 1px solid #fff; }

.back-purple {
  background: #7874d8; }

.back-white {
  background: #fff; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.inline-block {
  display: inline-block; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.flex-order {
  display: flex;
  flex-direction: row; }

.flex {
  display: flex;
  flex-wrap: wrap; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }
  .flex-center .flex-left {
    display: flex;
    flex-direction: column;
    align-items: left; }

.fix-main-bottom {
  position: fixed;
  bottom: 30px;
  left: 218px;
  right: 0; }

.main-block {
  padding-left: 218px; }

.back-white {
  background: #fff; }

.color-white, .text-white {
  color: #fff; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s; }

.fade-enter, .fade-leave-active {
  opacity: 0; }

.fade {
  opacity: 0; }

.appo {
  transition: opacity .7s; }

.follow {
  padding-left: 15px; }
